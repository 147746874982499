import cx from 'classnames';
import React from 'react';

import { BREAKPOINTS, useBreakpoint } from '../../../hooks/useBreakpoint';
import { Slider } from '../../module/Slider';
import { Text } from '../../module/Text';
import { Title } from '../../module/Title';
import { breakoutClasses } from '../../module/Width';
import { Wrapper } from '../../module/Wrapper';
import { gridClasses, gapClasses, gapSizes } from './CardGridOptions';
import { IconCard } from './IconCard';
import { ImageCard } from './ImageCard';
import { QuoteCard } from './QuoteCard';
import { StatsCard } from './StatsCard';
import { VideoCard } from './VideoCard';

export const CardGrid = ({ title, intro, items, buttons, theme }) => {
  const { screenWidth } = useBreakpoint();
  let slideColumns = 1;
  if (screenWidth > BREAKPOINTS.xs) slideColumns = 1.5;
  if (screenWidth > BREAKPOINTS.sm) slideColumns = 2;
  if (screenWidth > BREAKPOINTS.md)
    slideColumns = theme?.grid?.columns < 3 ? theme?.grid?.columns : 3;
  if (screenWidth > BREAKPOINTS.lg) slideColumns = theme?.grid?.columns || 3;

  return (
    <Wrapper
      id={title}
      theme={{ background: theme?.module?.background, text: theme?.intro?.color }}
      space={theme?.module?.spacing}
    >
      <div
        className={cx('flex flex-col gap-8', {
          ['text-left']: theme?.module?.align === 'left',
          ['text-center']: theme?.module?.align === 'center',
          ['text-right']: theme?.module?.align === 'right',
        })}
      >
        {Boolean(title?.trim().length) && (
          <Title
            size={theme?.title?.size}
            color={theme?.title?.color}
            font={theme?.title?.font}
            weight={theme?.title?.weight}
            align={theme?.module?.align}
          >
            {title}
          </Title>
        )}

        {intro && (
          <Text
            font={theme?.intro?.font}
            weight={theme?.intro?.weight}
            size={theme?.intro?.size}
            color={theme?.intro?.color}
            align={theme?.module?.align}
            as="div"
          >
            {intro}
          </Text>
        )}

        {Boolean(items?.length) && (
          <div className={cx('text-left', breakoutClasses)}>
            {theme?.grid?.slider && items?.length > slideColumns ? (
              <Slider
                gap={gapSizes[theme?.grid?.gap || 'none']}
                columns={slideColumns}
                slides={items?.map((item) => {
                  switch (item?._type) {
                    case 'card.icon':
                      return <IconCard {...item} key={item._key} />;
                    case 'card.video':
                      return <VideoCard {...item} key={item._key} />;
                    case 'card.image':
                      return <ImageCard {...item} key={item._key} />;
                    case 'card.stats':
                      return <StatsCard {...item} key={item._key} />;
                    case 'card.quote':
                      return <QuoteCard {...item} key={item._key} />;
                  }
                })}
              />
            ) : (
              <ul
                className={cx(
                  'grid',
                  gridClasses[theme?.grid?.columns || 2],
                  gapClasses[theme?.grid?.gap || 'none'],
                )}
              >
                {items?.map((item) => (
                  <li key={item._key} className="h-full">
                    {item?._type === 'card.icon' && <IconCard {...item} />}
                    {item?._type === 'card.video' && <VideoCard {...item} />}
                    {item?._type === 'card.stats' && <StatsCard {...item} />}
                    {item?._type === 'card.image' && <ImageCard {...item} />}
                    {item?._type === 'card.quote' && <QuoteCard {...item} />}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default React.memo(CardGrid);
