export const gridClasses = {
  1: 'grid-cols-1',
  2: 'sm:grid-cols-2',
  3: 'sm:grid-cols-2 md:grid-cols-3',
  4: 'sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4',
  5: 'sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5',
};

export const gapClasses = {
  none: 'gap-0',
  xs: 'gap-1',
  sm: 'gap-2',
  md: 'gap-4',
  lg: 'gap-7',
  xl: 'gap-10',
};

export const gapSizes = {
  none: 0,
  xs: 1 * 4,
  sm: 2 * 4,
  md: 4 * 4,
  lg: 7 * 4,
  xl: 10 * 4,
};
