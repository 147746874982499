import cx from 'classnames';

export const Gradient = ({ className, from = 0.6, to = 0.2 }) => (
  <div
    className={cx(
      'absolute inset-0 z-1',
      'mix-blend-multiply bg-gradient-to-b',
      { ['from-[rgba(0,0,0,0)]']: from === 0.0 },
      { ['from-[rgba(0,0,0,0.2)]']: from === 0.2 },
      { ['from-[rgba(0,0,0,0.4)]']: from === 0.4 },
      { ['from-[rgba(0,0,0,0.6)]']: from === 0.6 },
      { ['from-[rgba(0,0,0,0.8)]']: from === 0.8 },
      { ['from-[rgba(0,0,0,1)]']: from === 1 },
      { ['to-[rgba(0,0,0,0)]']: to === 0.0 },
      { ['to-[rgba(0,0,0,0.2)]']: to === 0.2 },
      { ['to-[rgba(0,0,0,0.4)]']: to === 0.4 },
      { ['to-[rgba(0,0,0,0.6)]']: to === 0.6 },
      { ['to-[rgba(0,0,0,0.8)]']: to === 0.8 },
      { ['to-[rgba(0,0,0,1)]']: to === 1 },
      className,
    )}
  />
);
