import cx from 'classnames';

import { aspectRatioClasses } from '../../../classes';
import ClickAction from '../../../components/ClickAction';
import { IconLoader } from '../../images/IconLoader';
import { ResponsiveImage } from '../../images/ResponsiveImage';
import { Gradient } from '../../module/Gradient';
import { Title } from '../../module/Title';
import { Card } from './Card';

export const VideoCard = ({ title, youtube, image, theme }) => {
  const ClickEl = youtube ? ClickAction : 'div';

  return (
    <Card space="none" background={theme?.background?.color}>
      <div className={cx('flex flex-col gap-3', aspectRatioClasses['16:9'])}>
        <ClickEl
          action={{
            type: 'modal',
            modal: {
              type: 'video',
              url: youtube,
            },
          }}
          section="Card Grid"
        >
          {image && (
            <div className="absolute inset-0 transform group-hover:scale-110 transition-transform ease-out duration-500 z-0">
              <ResponsiveImage {...image} layout="fill" />
            </div>
          )}

          <Gradient from={0} to={0.6} />

          {title && (
            <Title
              as="h3"
              align={theme?.align?.x}
              size={theme?.title?.size || '5xl'}
              color={theme?.title?.color || 'white'}
              className="absolute bottom-0 left-0 w-full p-10"
            >
              {title}
            </Title>
          )}

          {youtube && (
            <IconLoader
              className={cx(
                'w-16 h-16',
                'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
                'opacity-80 group-hover:opacity-100 transition-opacity',
              )}
              src="/icons/play-video.svg"
            />
          )}
        </ClickEl>
      </div>
    </Card>
  );
};
