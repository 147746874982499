import cx from 'classnames';
import React from 'react';

import {
  textClasses,
  alignClasses,
  fontClasses,
  sizeClasses,
  weightClasses,
  leadingClasses,
} from '../../classes';

export const Title = ({
  children,
  as = 'h2',
  size = 'base',
  weight = 'bold',
  font = 'heading',
  align = 'left',
  leading = 'tight',
  className,
  color,
}) => {
  const Element = as;

  return (
    <Element
      className={cx(
        'break-words inline-block',
        fontClasses[font],
        alignClasses[align],
        textClasses[color],
        sizeClasses[size],
        weightClasses[weight],
        leadingClasses[leading],
        className,
      )}
    >
      {children}
    </Element>
  );
};

export const TitleMemo = React.memo(Title);
