import { backgroundClasses } from 'classes';
import cx from 'classnames';

import { ResponsiveImage } from '../../images/ResponsiveImage';
import { Gradient } from '../../module/Gradient';
import { Title } from '../../module/Title';
import { Card } from './Card';
import { spaceHorizontalClasses, spaceVerticalClasses } from './CardOptions';

export const QuoteCard = ({ title, image, theme }) => {
  return (
    <Card space="none" background={theme?.background?.color}>
      <div
        className={cx(
          'flex flex-col gap-3 min-h-40',
          spaceHorizontalClasses[theme?.spacing?.x || 'md'],
          spaceVerticalClasses[theme?.spacing?.y || 'md'],
        )}
      >
        {image && (
          <div className="absolute inset-0 z-0">
            <ResponsiveImage {...image} layout="fill" roundSize={100} />
          </div>
        )}

        {!theme?.background?.color && <Gradient from={0} to={0.2} />}

        {theme?.background?.color && (
          <div
            className={cx(
              'absolute inset-0 z-0',
              'mix-blend-multiply',
              backgroundClasses[theme?.background?.color],
            )}
          />
        )}

        {title && (
          <Title
            as="h3"
            align={theme?.align?.x}
            size={theme?.title?.size || '5xl'}
            color={theme?.title?.color || 'white'}
            className="w-full relative z-10"
          >
            {title}
          </Title>
        )}
      </div>
    </Card>
  );
};
