import cx from 'classnames';

import { flexAlignClasses } from '../../../classes';
import { Text } from '../../module/Text';
import { Title } from '../../module/Title';
import { Card } from './Card';

export const StatsCard = ({ stat, title, text, theme }) => (
  <Card
    background={theme?.background?.color}
    text={theme?.text?.color}
    space="md"
    verticalAlign={theme?.align?.y}
  >
    <div className={cx('flex flex-col gap-3', flexAlignClasses[theme?.align?.x])}>
      {stat && (
        <Title
          as="strong"
          font="heading"
          size="7xl"
          leading="none"
          color={theme?.stat?.color}
          align={theme?.align?.x}
          className="!break-all"
        >
          {stat}
        </Title>
      )}
      {title && (
        <Title
          as="h3"
          size="2xl"
          color={theme?.title?.color}
          align={theme?.align?.x}
        >
          {title}
        </Title>
      )}
      {text && (
        <Text color={theme?.text?.color} align={theme?.align?.x}>
          {text}
        </Text>
      )}
    </div>
  </Card>
);
