import cx from 'classnames';

import { aspectRatioClasses } from '../../../classes';
import {
  ResponsiveImage,
  getOriginalImageDimensions,
} from '../../images/ResponsiveImage';
import { Card } from './Card';

export const ImageCard = ({ image, theme }) => {
  if (theme?.image?.aspectRatio === 'auto') theme.image.aspectRatio = null;

  const { aspectRatio } = image?.asset?._ref
    ? getOriginalImageDimensions(image?.asset?._ref)
    : '.5625';

  return (
    <Card space="none" background={theme?.background?.color}>
      <div
        style={{
          aspectRatio: theme?.image?.aspectRatio ? null : `${aspectRatio} / 1`,
        }}
        className={cx(
          'flex flex-col gap-3',
          aspectRatioClasses[theme?.image?.aspectRatio],
        )}
      >
        {image && (
          <div className="absolute inset-0 z-0">
            <ResponsiveImage {...image} layout="fill" />
          </div>
        )}
      </div>
    </Card>
  );
};
