export const spaceClasses = {
  none: 'p-0',
  xs: 'p-2',
  sm: 'p-5',
  md: 'p-10',
  lg: 'p-16',
  xl: 'p-20',
};

export const spaceHorizontalClasses = {
  none: 'px-0',
  xs: 'px-2',
  sm: 'px-5',
  md: 'px-10',
  lg: 'px-16',
  xl: 'px-20',
};

export const spaceVerticalClasses = {
  none: 'py-0',
  xs: 'py-2',
  sm: 'py-5',
  md: 'py-10',
  lg: 'py-16',
  xl: 'py-20',
};
