import cx from 'classnames';

import { ResponsiveImage } from '@/components/images/ResponsiveImage';

import { flexAlignClasses } from '../../../classes';
import ClickAction from '../../../components/ClickAction/index';
import { IconLoader } from '../../images/IconLoader';
import { Text } from '../../module/Text';
import { Title } from '../../module/Title';
import { Card } from './Card';
import { iconSizeClasses } from './IconCardOptions';

export const IconCard = ({ icon, image, title, text, clickAction, theme }) => (
  <Card
    background={theme?.background?.color}
    text={theme?.text?.color}
    space="md"
    verticalAlign={theme?.align?.y}
    className={cx({
      ['sm:pb-20']: clickAction,
    })}
  >
    <div
      className={cx('flex flex-col gap-3', flexAlignClasses[theme?.align?.x], {
        ['max-w-[calc(100%-40px)] sm:max-w-none']: clickAction,
      })}
    >
      {icon && (
        <IconLoader
          src={icon}
          color={theme?.icon?.color}
          className={cx(iconSizeClasses[theme?.icon?.size || 'md'])}
        />
      )}
      {image && (
        <div
          className={('relative', cx(iconSizeClasses[theme?.icon?.size || 'md']))}
        >
          <ResponsiveImage
            asset={image.asset}
            layout="fill"
            hotspot={image.hotspot}
            crop={image.crop}
          />
        </div>
      )}
      {title && (
        <Title
          as="strong"
          size="2xl"
          color={theme?.title?.color}
          align={theme?.align?.x}
          className={cx({
            ['group-hover:underline group-focus-within:underline']: clickAction,
          })}
        >
          {title}
          {clickAction && (
            <IconLoader
              src="/icons/streamline-light/Arrow Right 1.svg"
              color="currentColor"
              className={cx(
                'w-8 h-8',
                'absolute right-4 bottom-1/2 translate-y-1/2 sm:translate-y-0 sm:bottom-4',
                ' opacity-80 group-hover:opacity-100 group-focus-within:opacity-100 transition-opacity',
              )}
            />
          )}
        </Title>
      )}
      {text && (
        <Text color={theme?.text?.color} weight="normal" align={theme?.align?.x}>
          {text}
        </Text>
      )}

      {clickAction && (
        <div className="[&>a]:absolute [&>a]:inset-0">
          <ClickAction action={clickAction}>
            <span className="sr-only">{title}</span>
          </ClickAction>
        </div>
      )}
    </div>
  </Card>
);
