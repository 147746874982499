import cx from 'classnames';
import React from 'react';

import { slugify } from '../../helpers/utils/string';
import { Background } from './Background';
import { Spacing } from './Spacing';
import { Width } from './Width';

export const Wrapper = ({ children, theme, space = 'md', id, width = 'inner' }) => {
  const WidthEl = width ? Width : React.Fragment;
  return (
    <div id={id ? slugify(id) : null}>
      <Background theme={theme}>
        <div className={cx('relative z-1')}>
          <WidthEl>
            <Spacing space={space}>{children}</Spacing>
          </WidthEl>
        </div>
      </Background>
    </div>
  );
};

export const WrapperMemo = React.memo(Wrapper);
