import cx from 'classnames';
import React from 'react';

import { backgroundClasses, textClasses } from '../../classes';

export const Background = ({
  children,
  theme = { text: 'black', background: 'white' },
}) => {
  return (
    <div
      className={cx(
        'relative',
        backgroundClasses[theme?.background],
        textClasses[theme?.text],
      )}
    >
      <div className={cx('relative z-1')}>{children}</div>
    </div>
  );
};

export const BackgroundMemo = React.memo(Background);
