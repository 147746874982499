import cx from 'classnames';
import { useState } from 'react';
import SwiperCore, { Keyboard, Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

SwiperCore.use([Navigation, Pagination, Keyboard]);

export const Slider = ({ slides, columns, gap = 0 }) => {
  const [prevEl, setPrevEl] = useState(null);
  const [nextEl, setNextEl] = useState(null);
  const [paginationEl, setPaginationEl] = useState(null);

  if (!slides?.filter(Boolean).length) return null;

  return (
    <div className="relative">
      <Swiper
        spaceBetween={gap}
        slidesPerView={columns}
        // slidesPerGroup={columns} // this sort of breaks dragging behaviour, removing it for now
        grabCursor={!slides?.some(({ props }) => props.href)}
        modules={[Keyboard, Pagination, Navigation]}
        // preventClicks={false}
        // preventClicksPropagation={false}
        navigation={{ prevEl, nextEl }}
        // autoHeight={true}
        keyboard={{
          enabled: true,
        }}
        pagination={{
          el: paginationEl,
          clickable: true,
          type: 'fraction',
        }}
        autoHeight
      >
        {slides?.filter(Boolean).map((slide, n) => (
          <SwiperSlide className="self-stretch" key={n}>
            <div
              className={cx('h-full select-none', {
                ['swiper-no-swiping']: slide.props.href,
              })}
            >
              {slide}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="flex items-center gap-4 pt-8 pr-8 2xl:pr-0">
        <div
          className="flex justify-end items-center gap-2 w-full self-stretch text-xs"
          ref={(node) => setPaginationEl(node)}
        ></div>

        <div className="flex justify-end shrink-0 ml-auto">
          <button
            type="button"
            ref={(node) => setPrevEl(node)}
            className="disabled:opacity-10 transition-opacity"
          >
            <ArrowIcon className="w-12 h-12 rotate-180" />
          </button>
          <button
            type="button"
            ref={(node) => setNextEl(node)}
            className="disabled:opacity-10 transition-opacity"
          >
            <ArrowIcon className="w-12 h-12 " />
          </button>
        </div>
      </div>
    </div>
  );
};

const ArrowIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 23 22" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5996 20.5996C17.1216 20.5996 21.5996 16.1216 21.5996 10.5996C21.5996 5.07661 17.1216 0.599609 11.5996 0.599609C6.07761 0.599609 1.59961 5.07661 1.59961 10.5996C1.59961 16.1216 6.07761 20.5996 11.5996 20.5996Z"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.73828 10.5996H16.5473"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1523 13.9951L16.5473 10.5991L13.1523 7.20312"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
