import cx from 'classnames';

import {
  backgroundClasses,
  flexJustifyClasses,
  textClasses,
} from '../../../classes';
import { spaceClasses } from './CardOptions';

export const Card = ({
  children,
  background,
  text,
  space = 'md',
  verticalAlign = 'top',
  className,
}) => (
  <div
    className={cx(
      'h-full relative flex flex-col',
      'group',
      'overflow-hidden',
      [spaceClasses[space]],
      [backgroundClasses[background]],
      [textClasses[text]],
      [flexJustifyClasses[verticalAlign]],
      className,
    )}
  >
    {children}
  </div>
);
